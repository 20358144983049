import { push } from "connected-react-router";
import style from "css/LoginPage.module.scss";
import logo from "media/updatedLogo.png";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Form, Modal, Row } from "reactstrap";
import { login } from "redux/Auth/action";
import { IRootState } from "store";

export interface ILoginData {
  userLogin: string;
  password: string;
}

export default function LoginPage() {
  const loginData = useSelector((state: IRootState) => state.auth);
  const [forgetPasswordPage, setForgetPasswordPage] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginData>({
    defaultValues: {
      userLogin: "",
      password: "",
    },
  });
  const dispatch = useDispatch();

  async function onSubmit(data: ILoginData) {
    if (data.userLogin && data.password) {
      dispatch(login(data));
    }
  }
  const handleExit = () => {
    setForgetPasswordPage(false);
  };

  useEffect(() => {
    if (loginData.chiName && loginData.mobile && loginData.staffNumber) {
      dispatch(push("/order"));
    }
  }, [dispatch, loginData]);

  return (
    <div style={{ position: "relative", paddingTop: "2em" }}>
      <div className="mt-4">
       <img src={logo} style={{ maxHeight: "68px" }} alt="logo" />
      </div>
      <h2 style={{ fontSize: "1.6em", margin: "1.5em" }}>Goldtitan</h2>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        id={style["form"]}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <div className="flex-row-start full-width flex1" style={{ position: "relative" }}>
            <div
              style={{
                width: "30%",
                textAlign: "right",
                whiteSpace: "nowrap",
                paddingRight: "8px",
              }}
            >
              使用者:
            </div>
            <input
              className="my-2"
              type="text"
              {...register("userLogin")}
              placeholder="登記電話 ／ 電郵地址"
              style={{ width: "70%" }}
            />
            {errors.userLogin && <div className={style["error"]}>請填寫此項資料</div>}
          </div>
          <div className="flex-row-start full-width" style={{ position: "relative" }}>
            <div style={{ width: "30%", textAlign: "right", paddingRight: "8px" }}>密碼:</div>
            <input
              className="my-2"
              type="password"
              {...register("password")}
              placeholder="密碼"
              style={{ width: "70%" }}
            />
            {errors.password && <div className={style["error"]}>請填寫此項資料</div>}
          </div>
        </div>
        <div className="full-width flex-row-around" style={{ width: "100vw", maxWidth: "420px" }}>
          <Button className="w-40 my-4" onClick={() => setForgetPasswordPage(true)}>
            {/* <Button type="submit" color="primary" onClick={() => onSubmit(loginInput)}> */}
            忘記密碼
          </Button>
          <Button type="submit" color="primary" className="w-40 my-4">
            {/* <Button type="submit" color="primary" onClick={() => onSubmit(loginInput)}> */}
            登入
          </Button>
        </div>
      </Form>
      <ForgetPasswordModal
        isOpen={forgetPasswordPage}
        content={`請與公司聯絡 以重設帳戶密碼`}
        handleExit={handleExit}
      />
    </div>
  );
}

interface ForgetPasswordModalProps {
  isOpen: boolean;
  content: string;
  handleExit: () => void;
}

export const ForgetPasswordModal = (props: ForgetPasswordModalProps) => {
  const { isOpen, content, handleExit } = props;
  return (
    <Modal centered isOpen={isOpen}>
      <Container className="p-3">
        <Row className="mb-2 flex-center">
          <h6 className="flex-center">{content}</h6>
        </Row>
        <Row className="flex-center">
          <Button className="w-40" onClick={handleExit}>
            OK
          </Button>
        </Row>
      </Container>
    </Modal>
  );
};

import axios from "axios";
import { PlayIcon } from "components/IconsOnly";
import ViewImageModal from "components/ViewImageModal";
import "css/Images.css";
import "css/OrderByIdPage.css";
import { formatDateAndTime } from "helpers/date";
import { videoFormats } from "models/mediaFormats";
import { FetchedOrderImages, OrderById } from "models/responseModels";
import React, { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import { handleAxiosError } from "redux/Auth/action";
import { setIsLoading, setMessageModal } from "redux/Loading/action";
import { clearOrderByIdData, setImageModalDescription, setOrderByIdData } from "redux/Order/action";
import { IRootState } from "store";

export type ImageModalType = "VIEW" | "ADD" | "";

function ImageUploadPage() {
  const [fetchedImages, setFetchedImages] = useState<FetchedOrderImages[]>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [preview, setPreview] = useState<string | undefined>();
  const [alteredFiles, setAlteredFiles] = useState<File[]>();
  const [imageModal, setImageModal] = useState<{
    type: ImageModalType;
    fileName?: string;
    mediaId?: number;
  }>({
    type: "",
    fileName: "",
    mediaId: 0,
  });
  const orderId = useParams<{ id: string }>().id;
  const userRole = useSelector((state: IRootState) => state.auth.role);

  const dispatch = useDispatch();
  const description = useSelector((state: IRootState) => state.orderById.image.imageDescription);
  const orderData = useSelector((state: IRootState) => state.orderById.orderById);
  const orderCompleteDate = !!orderData.completeDate ? new Date(orderData.completeDate) : undefined;
  const is7DaysAfter =
    !!orderCompleteDate &&
    Date.now().valueOf() > orderCompleteDate.setDate(orderCompleteDate.getDate() + 7).valueOf();

  const fetchOrderById = async () => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_PATH}/orders/${orderId}`);
      const res = await axios.get<OrderById>(url.toString());
      const result = res.data;
      dispatch(setOrderByIdData(result));
    } catch (error) {
      dispatch(handleAxiosError(error));
    }
  };

  const postMedia = async () => {
    dispatch(setIsLoading(true));
    const formData = new FormData();
    if (!!alteredFiles) Array.from(alteredFiles).forEach((f) => formData.append("orderMedia", f));
    if (!!description) formData.append("description", description);
    try {
      await axios.post(`/order-media/${orderId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    } finally {
      fetchAllMediaByOrderId();
      dispatch(setIsLoading(false));
      handleExit();
    }
  };

  const fetchAllMediaByOrderId = async () => {
    dispatch(setIsLoading(true));
    try {
      const res = await axios.get(`/order-media/${orderId}`);
      const result = res.data;
      setFetchedImages(result.data);
    } catch (error) {
      dispatch(handleAxiosError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const editMedia = async (mediaId: number) => {
    dispatch(setIsLoading(true));
    try {
      await axios.put(`/order-media/${mediaId}`, {
        description: description,
      });
      dispatch(setMessageModal({ isOpen: true, content: "儲存成功" }));
    } catch (error) {
      dispatch(handleAxiosError(error));
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    } finally {
      fetchAllMediaByOrderId();
      dispatch(setIsLoading(false));
      handleExit();
    }
  };
  // AVAILABLE FOR FOREMEN and up ???
  // const handleDeleteMedia = async (mediaId: number) => {
  //   try {
  //     await axios.delete(`/order-media`, {
  //       data: {
  //         mediaIds: [mediaId],
  //       },
  //     });
  //   } catch (error) {
  //     dispatch(handleAxiosError(error));
  //   } finally {
  //     fetchAllMediaByOrderId();
  //     dispatch(setIsLoading(false));
  //     handleExit();
  //   }
  // };
  const handleExit = () => {
    setImageModal({ type: "" });
    dispatch(setImageModalDescription(""));
    setAlteredFiles(undefined);
  };

  useEffect(() => {
    fetchAllMediaByOrderId();
  }, []);

  useEffect(() => {
    fetchOrderById();
    return () => {
      dispatch(clearOrderByIdData());
    };
  }, [dispatch]);

  const compressImage = (file: File) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        4000,
        4000,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const convertVideoFormat = (video: File) => {
    const blob = video.slice(0, video.size, "video/mp4");
    const arr = video.name.split(".");
    return new File(
      [blob],
      video.name.slice(0, video.name.length - arr[arr.length - 1].length) + "mp4",
      {
        type: "video/mp4",
      }
    );
  };

  const uploadMediaHandling = async (files: FileList) => {
    let alteredArr: File[] = [];

    // loop through all files
    for (let f of Array.from(files)) {
      // change the file format if it's quicktime / mov, push the new file in array
      if ([".quicktime", ".QUICKTIME", ".mov", ".MOV"].some((i) => f.name.endsWith(i))) {
        const alteredFile = convertVideoFormat(f);
        alteredArr.push(alteredFile);

        // if the file is image, compress before uploading
      } else if (f.type.startsWith("image")) {
        const compressedImage = await compressImage(f);
        if (compressedImage instanceof File) alteredArr.push(compressedImage);
      } else {
        alteredArr.push(f);
      }
    }
    return alteredArr;
  };

  const allowToEdit = (!is7DaysAfter && userRole === "WORKER") || userRole !== "WORKER";

  const onChange = async (event: any) => {
    try {
      if (!event.target.files) {
        // setPreview(undefined);
        setImageModal({ type: "" });
        return;
      } else {
        // check number of files
        if (event.target.files?.length > 5) {
          dispatch(setMessageModal({ isOpen: true, content: "每次只可上傳不多於5個檔案！" }));
          return;
        }

        // convert video format & compress images
        const alteredArr = await uploadMediaHandling(event.target.files);

        // set the new altered array (with both altered & ok files) in use state
        setAlteredFiles(alteredArr);

        setImageModal({ type: "ADD" });
      }
    } catch (error) {
      console.error(error);
      dispatch(setMessageModal({ isOpen: true, content: "儲存失敗" }));
    }
  };

  return (
    <div
      className="m-0 full-width flex-column-start relative"
      style={{
        width: "100vw",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <div
        className="flex-column-center"
        style={{
          width: "100vw",
          overflowY: "auto",
        }}
      >
        {allowToEdit && (
          <form className="my-4 flex-center">
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*, video/*"
              name="orderMedia"
              multiple
              onChange={onChange}
            />
            <Button
              onClick={() => {
                handleExit();
                fileInputRef.current?.click();
              }}
              style={{ whiteSpace: "nowrap" }}
            >
              選擇檔案
            </Button>
          </form>
        )}
        {allowToEdit ? (
          <>
            <div style={{ color: "red" }}>*每次只可上傳不多於5個檔案</div>
            <div>工作單完工日期7天後，將無法上傳檔案</div>
          </>
        ) : (
          <div>工作單完工日期已過7天，無法上傳檔案</div>
        )}
        <Row
          className="px-3"
          style={{
            width: "100vw",
            minHeight: "64px",
            display: "grid",
            gridTemplateColumns: fetchedImages && !fetchedImages.length ? "1fr" : "1fr 1fr",
          }}
        >
          {fetchedImages && !fetchedImages.length ? (
            <Col>{"暫無圖片記錄"}</Col>
          ) : (
            fetchedImages?.map((item) => (
              <Col key={item.id} className="px-1 py-3 flex-column-start full-width imageContainer">
                {videoFormats.includes(item.fileName.split(".")[1]) ? (
                  <>
                    {/* <video
                    className="video"
                    style={{ maxWidth: `${window.innerWidth / 2 - 32}px` }}
                    src={`https://viewco-resources.s3.ap-southeast-1.amazonaws.com/${item.fileName}#t=0.1`}
                    onClick={() => {
                      setImageModal({
                        type: "VIEW",
                        fileName: item.fileName,
                        mediaId: item.id,
                      });
                      dispatch(setImageModalDescription(item.description));
                    }}
                  /> */}
                    <div
                      className="full-size flex-column-center"
                      onClick={() => {
                        setImageModal({
                          type: "VIEW",
                          fileName: item.fileName,
                          mediaId: item.id,
                        });
                        dispatch(setImageModalDescription(item.description));
                      }}
                    >
                      <PlayIcon />
                      {item.description && (
                        <div
                          className="my-1 flex-column-start"
                          style={{ maxHeight: "60%", overflowY: "scroll" }}
                        >
                          {item.description}
                        </div>
                      )}
                    </div>
                    <div className="flex-center dateContainer">
                      {formatDateAndTime(item.createdAt)}
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      alt={item.fileName}
                      // style={{ maxWidth: `${window.innerWidth / 2 - 32}px` }}
                      className="image"
                      src={`https://viewco-resources.s3.ap-southeast-1.amazonaws.com/${item.fileName}`}
                      onClick={() => {
                        setImageModal({
                          type: "VIEW",
                          fileName: item.fileName,
                          mediaId: item.id,
                        });
                        dispatch(setImageModalDescription(item.description));
                      }}
                    />
                    <div
                      className="flex-column-start py-1"
                      style={{
                        height: "fit-content",
                        width: "100%",
                        maxHeight: "48px",
                        overflowY: "auto",
                      }}
                    >
                      {item.description}
                    </div>
                    <div className="flex-center dateContainer">
                      {formatDateAndTime(item.createdAt)}
                    </div>
                  </>
                )}
              </Col>
            ))
          )}
          <div style={{ height: "280px" }} />
        </Row>
      </div>
      <ViewImageModal
        imageModal={imageModal}
        handleExit={handleExit}
        onSubmit={allowToEdit ? postMedia : () => {}}
        submitEdit={allowToEdit ? editMedia : () => {}}
      />
    </div>
  );
}

export default ImageUploadPage;
